import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Arrow from '../../../assets/Icons/Arrow.png';

import { getImageUrl } from '../../../shared/Utilities/index';

import classes from './Slider.module.scss';
import './Slider.scss';

const Slider = ({ isEnglish, slides, loading }) => {
  const count = slides.length;
  const [index, setIndex] = useState(0);

  const slideRight = () => {
    setIndex((index + 1) % count);
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    nextIndex < 0 ? setIndex(count - 1) : setIndex(nextIndex);
  };

  const selectSlide = (index, active) => {
    if (!active) {
      setTimeout(() => {
        setIndex(-1);
        setTimeout(() => {
          setIndex(index);
        }, 100);
      }, 300);
    }
  };

  useEffect(() => {
    const timer =
      !loading && count > 1 ? setInterval(() => slideRight(), 10000) : null;
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [index, count, loading]);

  const slider = slides.map((slide, id) => (
    <React.Fragment key={id}>
      {id === index ? (
        <>
          <img
            className={classes.slider_img_large}
            src={getImageUrl(slide.largeImageName)}
            alt=""
          />
          <img
            className={classes.slider_img_small}
            src={getImageUrl(slide.smallImageName)}
            alt=""
          />
        </>
      ) : (
        <></>
      )}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={id === index}
        timeout={{ enter: 1000, exit: 300 }}
        classNames="animated-slide-content"
      >
        <div className={classes.slider_content}>
          <h2 className={classes.slider_content_title}>
            {isEnglish ? slide.title_Eng : slide.title_Pol}
          </h2>
          <p className={classes.slider_content_text}>
            {isEnglish ? slide.article_Eng : slide.article_Pol}
          </p>
          <div className={classes.slider_content_btn_container}>
            {slide.buttons.map((btn, index) => (
              <a
                key={index}
                className={classes.slider_content_btn}
                href={btn.url}
                target="_blank"
                rel="noreferrer"
              >
                {isEnglish ? btn.name_eng : btn.name_pol}
              </a>
            ))}
          </div>
        </div>
      </CSSTransition>
    </React.Fragment>
  ));

  return (
    <section className={classes.slider_container}>
      <div className={classes.slider_text_background} />
      {loading ? <div className={classes.slider_img_placeholder} /> : slider}
      {!loading && count > 1 ? (
        <>
          <button className={classes.slider_arrow_left} onClick={slideLeft}>
            <img
              className={classes.slider_arrow_left_icon}
              src={Arrow}
              alt=""
            />
          </button>
          <button className={classes.slider_arrow_right} onClick={slideRight}>
            <img
              className={classes.slider_arrow_right_icon}
              src={Arrow}
              alt=""
            />
          </button>
          <div className={classes.slider_counter}>
            {slides.map((slide, id) => (
              <span
                className={
                  id === index
                    ? classes.slider_counter_item_active
                    : classes.slider_counter_item
                }
                key={id}
                onClick={() => selectSlide(id, id === index)}
              />
            ))}
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Slider;
