export const LANGUAGE_TOGGLE = 'LANGUAGE_TOGGLE';
export const LANGUAGE_CHECK_CURRENT = 'LANGUAGE_CHECK_CURRENT';

export const AUTH_LOGIN = 'AUTH_LOGIN';

export const HOME_PAGE_TOGGLE = 'HOME_PAGE_TOGGLE';

export const NEWS_FETCH_DATA = 'NEWS_FETCH_DATA';
export const NEWS_FETCH_START = 'NEWS_FETCH_START';
export const NEWS_FETCH_SUCCESS = 'NEWS_FETCH_SUCCESS';
export const NEWS_FETCH_FAILURE = 'NEWS_FETCH_FAILURE';
export const NEWS_UPDATE = 'NEWS_UPDATE';
export const NEWS_SET = 'NEWS_SET';
export const NEWS_CLEAR_ERROR = 'NEWS_CLEAR_ERROR';

export const GAMES_FETCH_DATA = 'GAMES_FETCH_DATA';
export const GAMES_FETCH_START = 'GAMES_FETCH_START';
export const GAMES_FETCH_SUCCESS = 'GAMES_FETCH_SUCCESS';
export const GAMES_FETCH_FAILURE = 'GAMES_FETCH_FAILURE';
export const GAMES_UPDATE = 'GAMES_UPDATE';
export const GAMES_SET = 'GAMES_SET';
export const GAMES_CLEAR_ERROR = 'GAMES_CLEAR_ERROR';
