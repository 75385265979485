import React from 'react';

const TrailerIcon = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0.6875C9.66371 0.6875 7.37989 1.38029 5.43733 2.67827C3.49478 3.97624 1.98074 5.8211 1.08668 7.97955C0.192618 10.138 -0.0413087 12.5131 0.414479 14.8045C0.870267 17.0959 1.9953 19.2007 3.64731 20.8527C5.29931 22.5047 7.4041 23.6297 9.6955 24.0855C11.9869 24.5413 14.362 24.3074 16.5205 23.4133C18.6789 22.5193 20.5238 21.0052 21.8217 19.0627C23.1197 17.1201 23.8125 14.8363 23.8125 12.5C23.8125 9.36713 22.568 6.36258 20.3527 4.1473C18.1374 1.93203 15.1329 0.6875 12 0.6875ZM12 5.75C12.2503 5.75 12.495 5.82423 12.7031 5.9633C12.9113 6.10237 13.0735 6.30003 13.1693 6.53129C13.2651 6.76255 13.2901 7.01703 13.2413 7.26254C13.1925 7.50804 13.0719 7.73356 12.8949 7.91056C12.7179 8.08756 12.4924 8.2081 12.2469 8.25693C12.0014 8.30577 11.7469 8.2807 11.5157 8.18491C11.2844 8.08912 11.0867 7.9269 10.9477 7.71877C10.8086 7.51064 10.7344 7.26594 10.7344 7.01563C10.7344 6.67996 10.8677 6.35804 11.1051 6.12069C11.3424 5.88334 11.6643 5.75 12 5.75ZM15.375 19.3555H8.62501V17.457H11.0508V12.6055H9.46876V10.707H12.9492V17.457H15.375V19.3555Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrailerIcon;
